@import url(https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900|Open+Sans:400italic,700italic,400,700);

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Work Sans', 'Open Sans', Helvetica, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

html {
  font-size: 10px;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  height: 100%;
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

.clickable {
  cursor: pointer;
}
